<template>
  <div>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6" xl="4">
          <alert-icons />

          <v-container class="px-0 text-center text-sm-start">
            <!-- Step 1: Email and Password -->
            <v-form
              v-if="!success"
              ref="setNewPasswordForm"
              v-model="valid"
              @submit.prevent="sendPasswordResetEmail"
            >
              <h1>{{ $t('setNewPassword') }}</h1>
              <p>{{ $t('setNewPasswordMsg') }}</p>
              <v-text-field
                v-model="email"
                :label="$t('email')"
                autofocus
                :rules="emailRules"
                :hint="$t('setNewPasswordEmailHelperText')"
                prepend-icon="fa-user"
                class="py-10"
              />

              <div class="d-flex py-3">
                <v-spacer />
                <v-btn
                  type="submit"
                  color="primary"
                  :loading="loading"
                  :block="$vuetify.breakpoint.xsOnly"
                  :disabled="!valid || loading"
                  @click.prevent="sendPasswordResetEmail"
                >
                  {{ $t('send') }}
                </v-btn>
              </div>
            </v-form>

            <div v-else class="text-center">
              <div class="d-flex justify-center pa-10">
                <v-icon color="primary" x-large>fa-envelope</v-icon>
              </div>
              <h1>{{ $t('setNewPasswordEmailSent') }}</h1>
              <p>{{ $t('setNewPasswordEmailSentMsg') }}</p>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import api from '@/util/api';
import { emailRules } from '@/consts/userRules';
import AlertIcons from '@/components/AlertIcons';
import { translate as t } from '@/util/i18n';
import errorMessage from '@/util/errorMessage';

export default {
  name: 'SetNewPassword',

  components: {
    AlertIcons,
  },

  data: () => ({
    success: false,
    loading: false,
    valid: false,
    emailRules: emailRules(() => false),
    email: '',
  }),

  methods: {
    sendPasswordResetEmail() {
      this.$refs.setNewPasswordForm.validate();
      if (this.valid) {
        this.loading = true;
        api
          .startResetPassword(this.email)
          .then(() => {
            this.loading = false;
            this.success = true;
          })
          .catch((res) => {
            this.loading = false;
            errorMessage(t('sendResetLinkError'), t('sendResetLinkErrorMsg'), res);
          });
      }
    },
  },
};
</script>
